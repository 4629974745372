import * as _ from 'lodash';
import * as moment from "moment/moment";

app.controller('InspectorController', ['$scope', '$rootScope', 'Backend', 'SiteConfiguration', '$location', 'Helpers',
    '$element', '$q', '$http', '$window', 'cfg', '$translate',
    function ($scope, $rootScope, Backend, SiteConfiguration, $location, Helpers, $element, $q, $http, $window, cfg,
              $translate) {
        const self = this;

        this.applicantFiles = {'list': [], 'count': 0, 'page': 0};
        this.serviceId = 'all';
        this.allServicesChoice = {service_id: 'all', name: $translate.instant('All services'), ordering: 0};
        this.selectedService = self.allServicesChoice;
        this.roleId = 'my';
        this.filter = {status: 'all', metaData: null, startedBy: null, fromDate: null, toDate: null};
        this.orderBy = 'startDate';
        this.reverse = true;
        this.page = 1;
        this.size = 10;
        this.GLOBALS = Helpers.GLOBALS;
        this.listingLoading = false;
        this.serviceOptions = [];
        this.messages = [];

        this.selectedHistory = [];

        // noinspection JSUnusedGlobalSymbols
        this.initController = function (size) {
            // Used in process_list.html
            self.size = size;
            self.loadFilterStateFromUrl();
            self.loadProcesses();
            self.setUpDateRangePicker();
            self.loadServiceOptions();
        };

        this.setUpDateRangePicker = function () {
            // TODO: Unify with partb.controller.js?
            const ranges = {};
            [
                {name: $translate.instant('Today'), value: [moment(), moment()]},
                {
                    name: $translate.instant('Yesterday'),
                    value: [moment().subtract(1, 'days'), moment().subtract(1, 'days')]
                },
                {name: $translate.instant('Last 7 Days'), value: [moment().subtract(6, 'days'), moment()]},
                {
                    name: $translate.instant('Last 30 Days'), value: [moment().subtract(29, 'days'),
                        moment()]
                },
                {
                    name: $translate.instant('This Month'), value: [moment().startOf('month'), moment()
                        .endOf('month')]
                },
                {
                    name: $translate.instant('Last Month'),
                    value: [moment().subtract(1, 'month').startOf('month'), moment()
                        .subtract(1, 'month').endOf('month')]
                }
            ].forEach(function (range) {
                ranges[range.name] = range.value;
            });
            const opensDir = LANG_DIRECTION === 'rtl' ? 'left' : 'right';
            const cb = function (start, end) {
                if (start && !(start instanceof moment)) { // in case of clearing
                    start = end = null;
                }
                self.filter.fromDate = start;
                self.filter.toDate = end;
                self.loadProcesses();
                if (start == null && end == null) {
                    $('#reportrange span').html('');
                } else {
                    $('#reportrange span').html(start.format('LL') + ' - ' + end.format('LL'));
                }
            }
            const containerDiv = $('#reportrange');
            const dateRangePicker = containerDiv.daterangepicker({
                locale: {
                    cancelLabel: $translate.instant('Clear'),
                    applyLabel: $translate.instant('Apply')
                },
                showCustomRangeLabel: false,
                alwaysShowCalendars: true,
                linkedCalendars: false,
                opens: opensDir,
                ranges: ranges,
                isToday: function (date) {
                    // Highlight today's date using a custom class
                    return date.isSame(moment(), 'day') ? 'today' : false;
                },
                maxDate: moment()
            }, cb);
            containerDiv.on('cancel.daterangepicker', cb);
            dateRangePicker.on('show.daterangepicker', function (ev, picker) {
                picker.container[0].classList.add('visible-calendars');
            });
            dateRangePicker.on('hide.daterangepicker', function (ev, picker) {
                picker.container[0].classList.remove('visible-calendars');
            });
        }

        this.loadFilterStateFromUrl = function () {
            // Only done once on landing
            self.filter.serviceId = $location.search().serviceId || 'all';
            self.filter.status = $location.search().status || 'all';
            self.filter.metaData = $location.search().metaData || null;
            if (!self.filter.startedBy) {
                self.filter.startedBy = {};
            }
            self.filter.startedBy.auth_system_id = $location.search().startedBy || null;
            self.filter.startedBy.full_name = $location.search().startedBy || null;
            self.filter.fromDate = $location.search().fromDate ? moment($location.search().fromDate) : null;
            self.filter.toDate = $location.search().toDate ? moment($location.search().toDate) : null;
            if (self.filter.fromDate && self.filter.toDate) {
                $('#reportrange span').html(self.filter.fromDate.format('LL') + ' - ' + self.filter.toDate.format('LL'));
            }
            self.page = $location.search().page || 0;
            self.size = $location.search().size || 10;
        }

        this.saveFilterStateIntoUrl = function () {
            $location.search('status', self.filter.status || null);
            $location.search('metaData', self.filter.metaData || null);
            $location.search('serviceId', self.filter.serviceId || null);

            if (self.filter.startedBy && self.filter.startedBy.auth_system_id) {
                $location.search('startedBy', self.filter.startedBy.auth_system_id);
            } else {
                $location.search('startedBy', null);
            }
            $location.search('fromDate', self.filter.fromDate ? self.filter.fromDate.format() : null);
            $location.search('toDate', self.filter.toDate ? self.filter.toDate.format() : null);
            $location.search('page', self.page || null);
            $location.search('size', self.size || null);
        }

        this.loadServiceOptions = function () {
            Backend.getServices().then(function (data) {
                Array.prototype.push.apply(self.serviceOptions, [self.allServicesChoice]);
                Array.prototype.push.apply(self.serviceOptions, data);
                if (self.filter.serviceId && self.filter.serviceId !== 'all') {
                    self.selectedService = _.find(self.serviceOptions, {service_id: self.filter.serviceId});
                }
            });
        }

        this.loadProcesses = function () {
            self.saveFilterStateIntoUrl();
            self.listingLoading = true;
            Backend.getFileListInProcessPage(self.serviceId, self.roleId, self.collectFilters()).then(function (data) {
                data.list.forEach(function (file) {
                    Helpers.setFileState(file);
                });
                self.applicantFiles = data;
                self.listingLoading = false;
            });
        }

        this.collectFilters = function () {
            const filters = {};
            if (self.filter.serviceId && self.filter.serviceId !== 'all') {
                filters.serviceId = self.filter.serviceId;
            }
            if (self.filter.status && self.filter.status !== 'all') {
                filters.status = self.filter.status;
            }
            if (self.filter.metaData) {
                filters.metaData = self.filter.metaData;
            }
            if (self.filter.startedBy) {
                filters.startedBy = self.filter.startedBy.auth_system_id;
            }
            if (self.filter.fromDate) {
                filters.fromDate = self.filter.fromDate.startOf('day').format();
            }
            if (self.filter.toDate) {
                filters.toDate = self.filter.toDate.endOf('day').format();
            }
            filters.page = self.page;
            filters.size = self.size;
            filters.orderBy = self.orderBy;
            filters.sortOrder = self.reverse ? 'desc' : 'asc';

            return Object.keys(filters).length ? filters : null;
        };

        // noinspection JSUnusedGlobalSymbols
        this.pageChanged = _.debounce(function (new_page) {
            // FIXME: causes double loading on init
            // Used in process_list.html
            self.page = new_page ? new_page - 1 : 0;
            self.loadProcesses();
        }, 400);

        // noinspection JSUnusedGlobalSymbols
        this.sortFilesBy = function (value, data_type) {
            // Used in process_list.html
            self.reverse = (value !== null && self.orderBy === value) ? !self.reverse : false;
            self.orderBy = value;
            self.loadProcesses();
        };

        this.formatStatusBadge = function(type) {
            switch (type) {
                case 'filedecline':
                    return 'warning';
                case 'filereject':
                    return 'danger';
                case 'filevalidated':
                    return 'success';
                case 'filepending':
                    return 'info';
                default:
                    return 'default';
            }
        }

        this.fileHistory = function(file) {
            console.log("--- file history", file);
            const historyDrawer = document.querySelector('#history-drawer');
            const historyDrawerContent = document.querySelector('#history-drawer-content');
            const backdrop = document.querySelector('#drawer-backdrop');
            const correctionslist = document.querySelector('#corrections-list');
            correctionslist.replaceChildren();
            const previousHistory = document.querySelectorAll('.history-container');
            if (previousHistory && previousHistory.length) {
                previousHistory.forEach((p) => {
                    p.remove();
                });
            }
            let history = document.createElement('div');
            history.classList.add('history-container');

            if (file.tasks.length) {
                
                file.tasks.reverse();
                /* TO PUT BACK ONCE GOOD HISTOY IS HERE
                if (options.state === "filedecline") {
                    options.process_history.shift();
                }
                */
                file.tasks.forEach((h) => {
                    let historyItem = document.createElement('div');
                    historyItem.classList.add('history-item', 'closed');
                    let hitoryItemRail = document.createElement('div');
                    hitoryItemRail.classList.add('history-item-rail');
                    let hitoryItemRailIcon = document.createElement('i');
                    hitoryItemRailIcon.classList.add('fa-circle-dot');
                    if (h.status === 'filedecline') {
                        hitoryItemRailIcon.classList.add('fad', 'text-warning');
                    } else {
                        hitoryItemRailIcon.classList.add('far');
                    }
                    hitoryItemRail.append(hitoryItemRailIcon);
                    let hitoryItemContent = document.createElement('div');
                    hitoryItemContent.classList.add('history-item-content');
                    let hitoryItemContentTitle = document.createElement('h4');
                    hitoryItemContentTitle.classList.add('mb-0');
                    hitoryItemContentTitle.append($translate.instant(h.shortname));
                    let hitoryItemContentTags = document.createElement('small');
                    hitoryItemContentTags.innerHTML = `<b>${$translate.instant('By')}</b><b>${h.ownerFullName || h.assignee}</b><span>${$translate.instant('on')}</span><span>${h.endTime ? new Date(h.endTime).toLocaleString() : new Date(h.startTime).toLocaleString()}</span>`;
                    let historyItemBadge = document.createElement('span');
                    historyItemBadge.classList.add('status-badge', 'status-bg', `status-${self.formatStatusBadge(h.status)}`);
                    historyItemBadge.append($translate.instant('file_state_' + h.status));
                    hitoryItemContent.append(hitoryItemContentTitle, hitoryItemContentTags, historyItemBadge);
                    if (h.statusDescription && h.statusDescription !== 'filevalidated') {
                        let historyItemInfo = document.createElement('i');
                        historyItemInfo.classList.add('history-info', 'fal', 'fa-circle-info', 'status-badge');
                        let historyItemDescription = document.createElement('p');
                        historyItemDescription.classList.add('history-alert', `status-${self.formatStatusBadge(h.status)}`);
                        historyItemDescription.innerHTML = $translate.instant(h.statusDescription);
                        let historyItemBullet = document.createElement('li');
                        historyItemBullet.innerHTML = $translate.instant(h.statusDescription);
                        correctionslist.append(historyItemBullet);
                        hitoryItemContent.append(historyItemInfo, historyItemDescription);
                        historyItem.classList.add('clickable');
                        historyItem.addEventListener('click', function() {
                            historyItem.classList.toggle('closed');
                            historyItemInfo.classList.toggle(`status-${self.formatStatusBadge(h.status)}`);
                            historyItemInfo.classList.toggle('status-bg');
                        });
                    }

                    historyItem.append(hitoryItemRail, hitoryItemContent);
                    history.append(historyItem);
                });
                historyDrawerContent.append(history);
                historyDrawer.classList.remove('hidden');
                backdrop.classList.remove('hidden');
                self.toggleHistoryDrawer();
            }
        }

        this.toggleHistoryDrawer = function() {
            const historyDrawer = document.querySelector('#history-drawer');
            const backdrop = document.querySelector('#drawer-backdrop');
            historyDrawer.classList.toggle('closed');
            backdrop.classList.toggle('closed');
        }

        this.toggleMessagesDrawer = function() {
            const messagesDrawer = document.querySelector('#messages-drawer');
            const backdrop = document.querySelector('#drawer-backdrop');
            messagesDrawer.classList.toggle('closed');
            backdrop.classList.toggle('closed');
        }

        this.fillMessages = function(messages) {
            const messagesDrawer = document.querySelector('#messages-drawer');
            const messagesDrawerContent = document.querySelector('#messages-drawer-content');
            const backdrop = document.querySelector('#drawer-backdrop');
            const previousContainers = document.querySelectorAll('.message-container');
            if (previousContainers && previousContainers.length) {
                previousContainers.forEach((p) => {
                    p.remove();
                });
            }
            if (messages?.length) {
                messages.forEach((m) => {
                    let messageContainer = document.createElement('div');
                    messageContainer.classList.add('message-container');
                    let messageTitle = document.createElement('h4');
                    messageTitle.append(GLOBALS.$translate(m.subject));
                    let messageToggler = document.createElement('i');
                    messageToggler.classList.add('far', 'fa-angle-down', 'message-toggler');
                    let messageContent = document.createElement('div');
                    messageContent.classList.add('message-content');
                    messageContent.innerHTML = GLOBALS.$translate(m.content);
                    let messageTags = document.createElement('small');
                    messageTags.classList.add('message-tags');
                    messageTags.innerHTML = `<b>${GLOBALS.$translate('By')}</b><b>${m.message_from}</b><span>${GLOBALS.$translate('on')}</span><span>${m.updated_at ? new Date(m.updated_at).toLocaleString() : new Date(m.created_at).toLocaleString()}</span>`;
                    if (m.content) {
                        messageContent.classList.add('hidden');
                        messageToggler.addEventListener('click', function() {
                            messageContent.classList.toggle('hidden');
                            messageToggler.classList.toggle('fa-angle-down');
                            messageToggler.classList.toggle('fa-angle-up');
                        });
                        messageContainer.append(messageToggler);
                    }
                    messageContainer.append(messageTitle, messageContent, messageTags);
                    messagesDrawerContent.append(messageContainer);
                });    
            } else {
                let messageContainer = document.createElement('div');
                messageContainer.classList.add('message-container');
                let messageContent = document.createElement('div');
                messageContent.classList.add('message-content');
                messageContent.innerHTML = GLOBALS.$translate("No message yet.");
                messageContainer.append(messageContent);
                messagesDrawerContent.append(messageContainer);
            }
            messagesDrawer.classList.remove('hidden');
            backdrop.classList.remove('hidden');
            self.toggleMessagesDrawer();
        }

        this.rolesPopover = function(file_id, e) {
            e.preventDefault();
            const element = $(e.currentTarget);
            const contentElement = $('[content-id="' + file_id + '"]');
            if (element.data()['bs.popover'] && element.data()['bs.popover']['$tip'].hasClass('in')) {
                return;
            }
            element.popover({
                customClass: 'roles-statuses-popover',
                content: contentElement,
                trigger: 'hover',
                placement: 'top',
                html: true,
                sanitize: false
            });
            const bsPopover = element.data()['bs.popover'];
            const mouseCallback = (e) => {
                if (element.data()['bs.popover']) {
                    const tip = element.data()['bs.popover']['$tip'];
                    if (!tip.is(e.target) && tip.has(e.target).length === 0 && !element.is(e.target) && element.has(e.target).length === 0) {
                        bsPopover.hide();
                    } else {
                        return;
                    }
                }
                $(document).off('click', mouseCallback);
            }
            element.on('hidden.bs.popover', () => {
                element.css('opacity', "");
                $(document).off('click', mouseCallback);
            });
            element.css('opacity', 1);
            element.popover('show');
            $(document).click(mouseCallback);
        }

        this.loadMessages = function(file) {
            if (file.id) {
                Backend.getFileMessages(file.id).then(function (messages) {
                    console.log(messages);
                    self.fillMessages(messages.results);
                });
            }
        }

        $scope.getCompanies = function (lookup) {
            return Backend.getCompanies(lookup);
        };

        $scope.handleCompanyAutocompleteInputChange = function () {
            let inputValue = self.filter.metaData;
            if (!inputValue) {
                // Handle loading results when the input is emptied or cleared
                return self.loadProcesses();
            }
        };

        $scope.handleServiceChange = function () {
            self.filter.serviceId = self.selectedService.service_id;
            self.loadProcesses();
        }
    }]);